import { GetStaticPaths, GetStaticProps } from "next";
import { FunctionComponent } from "react";
import {
  CategoryCarousel,
  SuperHero,
  ConversionCopy,
} from "@bluebottlecoffee/design-system/components";

import {
  TranslationGroupSanityType,
  buildTranslationGroupQuery,
  toCopyObject,
} from "../../../lib/sanity/translation-group-queries";
import { shippingCopyQuery } from "../../../lib/sanity/product-queries";
import {
  flavorAndRoastCarousel,
  quickShopCarouselCardQuery,
} from "../../../lib/sanity/quick-shop-carousel-queries";
import { isFeatureEnabled } from "../../../lib/utils/is-feature-enabled";
import { MiniCartDialog } from "../../../components/MiniCartDialog";
import { toCategoryCarouselProps } from "../../../lib/transformers/category-carousel";
import sanityClient from "../../../lib/sanity-client";
import renderModule from "../../../lib/render-module";
import { toSuperHeroProps } from "../../../lib/transformers/super-hero";
import { LayoutWrapper } from "../../../components/LayoutWrapper";
import {
  getDefaultStaticPaths,
  getDefaultStaticPropsMiniCart,
} from "../../../lib/utils";
import { Homepage, ShippingCopy } from "../../../lib/sanity-schema";
import { CartBaseProps } from "../../../lib/sanity/shared";
import { filters } from "../../../lib/sanity/utils";

interface PageData extends CartBaseProps {
  homepage: Homepage;
  quickShopCopy: ShippingCopy & ConversionCopy;
}

export const getStaticProps: GetStaticProps<PageData> = async (context) => {
  const { props: defaultProps } = await getDefaultStaticPropsMiniCart(context);
  const { lang } = defaultProps;
  const { excludeDrafts, whereType } = filters;

  const homepage = await sanityClient().fetch<Homepage>(`*[
    ${whereType("homepage")} &&
    ${excludeDrafts}
  ]{
    ...,
    ${"modules"}[]{
      ...,
      ${quickShopCarouselCardQuery(lang)},
      ${flavorAndRoastCarousel(lang)}
    }
  }[0]`);

  const translationGroupQuery = buildTranslationGroupQuery(lang, "Conversion");

  const [shippingCopy, conversionCopy] = await Promise.all([
    sanityClient().fetch<ShippingCopy>(shippingCopyQuery),
    sanityClient()
      .fetch<TranslationGroupSanityType>(translationGroupQuery)
      .then((translations: TranslationGroupSanityType) =>
        toCopyObject<ConversionCopy>(lang, translations),
      ),
  ]);

  return {
    props: {
      homepage,
      quickShopCopy: {
        ...shippingCopy,
        ...conversionCopy,
      },
      ...defaultProps,
    },
  };
};

export const getStaticPaths: GetStaticPaths = getDefaultStaticPaths;

function validate(data: Homepage) {
  if (!data.title) {
    throw Error("Homepage must have a title.");
  }
}

const HomePage: FunctionComponent<PageData> = ({
  homepage,
  quickShopCopy,
  ...layoutWrapperProps
}) => {
  validate(homepage);
  const {
    region,
    lang,
    appPlayLinks,
    flavorProfileCopy,
    navAndCartInfoBanner,
    productRecs,
    giftCardFormCopy,
    cart,
    subscribableProducts,
  } = layoutWrapperProps;

  const dataHomepageCategoryCarouselMobile =
    homepage.categoryCarouselMobile?.[0];

  const categoryCarouselProps =
    dataHomepageCategoryCarouselMobile &&
    toCategoryCarouselProps(dataHomepageCategoryCarouselMobile, region, lang);

  const miniCartDialog = (
    <MiniCartDialog
      productRecs={productRecs}
      giftCardFormCopy={giftCardFormCopy}
      miniCartCopy={cart}
      navAndCartInfoBanner={navAndCartInfoBanner}
      region={region}
      lang={lang}
      subscribableProducts={
        isFeatureEnabled(
          process.env.NEXT_PUBLIC_CART_SUBSCRIPTION_TOGGLE_ENABLED,
        )
          ? subscribableProducts
          : []
      }
    />
  );

  return (
    <LayoutWrapper
      {...{
        ...layoutWrapperProps,
        pageTitle: homepage.title[lang],
        metaTags: homepage.metaTags,
        slideOutContent: [miniCartDialog],
      }}
      isTransparent={false}
    >
      {dataHomepageCategoryCarouselMobile && (
        <CategoryCarousel
          {...categoryCarouselProps}
          categoryCards={categoryCarouselProps.categoryCards.map((card) => ({
            ...card,
            roundedImage: true,
          }))}
          nElementsToDisplayOnMobile={3}
          leftPadding
          mobileOnly
          isNativeCarousel
        />
      )}

      {homepage.hero?.length > 0 && (
        <SuperHero
          heroes={toSuperHeroProps(homepage.hero, region, lang).heroes}
          isCarousel
        />
      )}

      {homepage.modules?.length > 0 &&
        homepage.modules.map((module) =>
          renderModule({
            module,
            region,
            lang,
            appPlayLinks,
            flavorProfileCopy,
            quickShopCopy,
          }),
        )}
    </LayoutWrapper>
  );
};

export default HomePage;
